import * as React from "react"
const Logo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={232}
    height={158}
    fill="none"
    {...props}
  >
    <path fill="#F6F6F6" d="M0 0h232v158H0z" />
    <path
      fill="#E07643"
      d="M53.233 65.7c5.117 5.134 5.117 13.486 0 18.62l-.157.157c-5.04 5.057-13.21 5.057-18.25 0-5.083-5.098-5.32-13.4-.335-18.597 5.103-5.312 13.565-5.374 18.742-.18Zm4.121-4.132c-7.4-7.424-19.4-7.424-26.804 0-7.4 7.424-7.4 19.463 0 26.89l13.402 13.444 13.402-13.444c7.4-7.427 7.4-19.466 0-26.89Z"
    />
    <path
      fill="#E07643"
      d="M53.233 65.7c5.117 5.134 5.117 13.486 0 18.62l-.157.157c-5.04 5.057-13.21 5.057-18.25 0-5.083-5.098-5.32-13.4-.335-18.597 5.103-5.312 13.565-5.374 18.742-.18Zm4.121-4.132c-7.4-7.424-19.4-7.424-26.804 0-7.4 7.424-7.4 19.463 0 26.89l13.402 13.444 13.402-13.444c7.4-7.427 7.4-19.466 0-26.89Z"
    />
    <path
      fill="#E07643"
      d="M43.955 82.08c-1.911 0-1.911-.65-1.911-1.453l.287-3.635a4.398 4.398 0 0 1-2.726-4.077c0-2.428 1.947-4.396 4.347-4.396 2.4 0 4.347 1.968 4.347 4.396a4.394 4.394 0 0 1-2.725 4.077l.287 3.635c.006.803.006 1.454-1.906 1.454Z"
    />
    <path
      fill="#282828"
      d="M101.019 69.424H89.578l-5.85 9.06v-16.06h-6.02a3.469 3.469 0 0 0-3.472 3.466v28.97h9.488v-5.742l1.845-2.474 4.373 8.216h11.648l-9.33-13.852 8.759-11.584Z"
    />
    <path
      fill="#282828"
      d="M114.719 68.488c-3.892 0-8.687 1.119-12.151 3.89l3.25 5.914c2.025-1.543 4.746-2.505 7.143-2.505 3.145 0 4.798 1.386 4.798 3.196v2.662c-1.386-1.757-4.422-2.823-7.781-2.823-3.837 0-8.849 1.81-8.849 8.204 0 5.858 5.012 8.471 8.849 8.471 3.197 0 6.342-1.276 7.781-3.09v2.452h9.593V79.302c0-8.576-6.234-10.814-12.633-10.814Zm3.04 19.39c-.639.906-2.292 1.386-3.784 1.386-1.6 0-3.359-.48-3.359-2.076 0-1.6 1.759-2.13 3.359-2.13 1.492 0 3.145.481 3.784 1.386v1.434ZM158.07 69.4h-11.438l-5.85 9.061V62.405h-6.019a3.47 3.47 0 0 0-3.472 3.466v28.97h9.487v-5.742l1.845-2.474 4.374 8.216h11.648l-9.326-13.852L158.07 69.4Z"
    />
    <path
      fill="#282828"
      d="M173.879 87.101c-1.266 0-2.108-1.055-2.108-2.373V77.62h5.163V69.4h-5.163v-6.954h-6.019a3.468 3.468 0 0 0-3.468 3.466V69.4h-4.22v8.216h4.22v9.85c0 5.32 3.111 8.005 8.751 8.005 3.216 0 5.429-.788 6.429-1.682l-1.684-7.165c-.214.21-1.055.477-1.901.477ZM202.825 69.4h-6.019v15.697c-.793.893-1.898 2-4.006 2-2.318 0-3.69-1.002-3.69-3.581V69.4h-9.487v18.17c0 4.37 2.532 7.9 8.435 7.9 4.479 0 7.275-1.843 8.751-3.473v2.843h9.488V72.867a3.467 3.467 0 0 0-3.472-3.466Z"
    />
    <path
      fill="#E07643"
      d="M146.562 66.655h9.961a3.468 3.468 0 0 0 3.468-3.47v-.743h-13.429v4.213Z"
    />
  </svg>
)
export default Logo

import React, { useState, useEffect } from 'react';
import Logo from './assets/logo';
import ComingSoon from './assets/comingSoon';
import './App.css'; // Import the CSS file for animations

const App = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const checkScreenSize = () => {
      setWindowWidth(window.innerWidth);
    };
    // Check initially and whenever the window resizes
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);
    return (
        // lg:max-w-max lg:w-full lg:mx-auto lg:flex flex-col items-center lg:justify-center space-y-2
        <div className="lg:w-full lg:mx-auto lg:flex flex-col lg:justify-center lg:max-w-[2500px]">
            <div className='bg-[#F6F6F6]  min-h-screen flex flex-col'>
                {/* <div className="ml-[100px] flex flex-col justify-around"> */}
                <div className="flex md:ml-28 justify-start sm:mb-20">
                    <Logo />
                </div>
                <div className="flex flex-col pb-20  justify-center flex-grow space-y-2">
                    <div className="text-start mb-16  space-y-2 md:ml-36 ml-8">
                        <h1 className={`${windowWidth > 1405 ? "lg:text-[111px]" : "lg:text-[70px]"} text-3xl md:text-5xl text-[#282828] font-bold uppercase`} style={{ fontFamily: 'Outfit, sans-serif' }}>
                            SOMETHING AWESOME 
                        </h1>
                        <h1 className={`${windowWidth > 1405 ? "lg:text-[111px]" : "lg:text-[70px]"} text-3xl text-[#282828] md:text-5xl font-bold uppercase`} style={{ fontFamily: 'Outfit, sans-serif' }}>
                            IS COMING
                            <span className="dot-loader">
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                            </span>
                        </h1>
                        <p className="md:text-2xl text-lg font-normal text-[#41464A]">Experience the next level of our upgraded</p>
                        <p className="md:text-2xl text-lg font-normal text-[#41464A]">platform!</p>
                    </div>
                    <div className="w-full">
                        <ComingSoon/>
                    </div>
                </div>
                {/* </div> */}
                
                
            </div>
         </div>

    );
};

export default App;
